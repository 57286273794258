:root {
  --primary-color: rgba(116,0,184,1);
  --primary-font: sans-serif;
  --shadow: 0 10px 15px -3px rgba(0,0,0,.2), 0 4px 6px -2px rgba(0,0,0,.1);
}

html {
  font-size: 18px;
}

body {
  background-color: rgb(238, 230, 255);
  color: #333;
  font-family: var(--primary-font);
  margin: 0;
}

header,
footer {
  background: var(--primary-color);
  background: linear-gradient(
    120deg, 
    rgba(116,0,184,1) 0%, 
    rgba(105,48,195,1) 10%, 
    rgba(94,96,206,1) 20%, 
    rgba(83,144,217,1) 30%, 
    rgba(78,168,222,1) 40%, 
    rgba(72,191,227,1) 50%, 
    rgba(86,207,225,1) 60%,
    rgba(100,223,223,1) 70%, 
    rgba(114,239,221,1) 80%, 
    rgba(128,255,219,1) 100%
  );
  color: #fff;
}

.container {
  margin: auto;
  max-width: 960px;
  padding: 1rem;
}

h1 {
  align-items: center;
  display: flex;
  font-size: 2rem;
  margin: 0;
  text-shadow: 0px 1px 1px var(--primary-color);
}

main {
  text-align: center;
}

.emoji {
  align-items: center;
  background: #fff;
  border-radius: 1rem;
  box-shadow: var(--shadow);
  display: flex;
  height: 4rem;
  font-size: 4rem;
  justify-content: center;
  line-height: 1;
  margin: 1rem auto 2rem auto;
  overflow: hidden;
  padding: 2rem;
  width: 4rem;
}

p {
  font-size: 1rem;
  margin: 0 0 1rem 0;
  line-height: 1.5;
}

input {
  border: none;
  border-radius: 8px;
  border-bottom-width: .25rem;
  box-shadow: var(--shadow);
  box-sizing: border-box;
  color: #333;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  font-family: var(--primary-font);
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  max-width: 400px;
  margin: 0 auto 2rem auto;
}

button {
  background-color: var(--primary-color);
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: var(--primary-font);
  font-size: 1rem;
  margin: 0 auto 1rem auto;
  max-width: 400px;
  line-height: 1;
  padding: 1rem 2rem;
  position: relative;
  transition: .2s;
  width: 100%;
}

button:active {
  top: 2px;
}

button:hover {
  filter: saturate(70%);
}

.result-list {
  margin: 0 0 .5rem 0;
  padding: 0;
}

li {
  background-color: var(--primary-color);
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  line-height: 1;
  list-style: none;
  margin-bottom: .5rem;
  overflow: visible;
  padding: .5rem;
  text-align: left;
  text-shadow: 1px 1px 2px #000;
  transition: .5s width;
  white-space: nowrap;
  width: auto;
}

.note {
  color: var(--primary-color);
  font-size: .875rem;
  font-style: italic;
}

.total-answers {
  font-weight: bold;;
  text-align: left;
}

.badge {
  display: inline-block;
  padding: .25rem;
  background: #00c115;
  border-radius: 4px;
  font-size: .85rem;
  font-weight: bold;
  color: #fff;
}

.space-below-1 {
  margin-bottom: 1rem;
}

.space-left-1 {
  margin-left: 1rem;
}

.badge + .badge {
  margin-left: .25rem;
}